import React from "react";
import styled from "styled-components";
import { Container, Row, Col } from "react-bootstrap";
import { Title, Button, Section, Box, Text, Input, Select } from "../components/Core";
import PageWrapper from "../components/PageWrapper";
import { device } from "../utils";
import { graphql } from "gatsby";
import { Helmet } from "react-helmet"

const FormStyled = styled.form``;
const WidgetWrapper = styled(Box)`
  border-radius: 10px;
  background-color: #f7f7fb;
  padding-top: 50px;
  padding-bottom: 30px;
  padding-left: 30px;
  padding-right: 30px;
  @media ${device.lg} {
    padding-left: 40px;
    padding-right: 40px;
  }
  @media ${device.xl} {
    padding-left: 70px;
    padding-right: 70px;
  }
`;

const defaultContactInfo = {
  emailAddress: 'info@memhse.com',
   canadaOfficePhoneNumber: '778-918-9304',
   canadaOfficeEmail: '',
   canadaOfficeAddressHTML: null,
   usaOfficePhoneNumber: '206-949-5354',
   usaOfficeEmail: '',
   usaOfficeAddressHTML: null,
}

const subjectOptions = [
  {value: 'General Inquiries', label: 'General Inquiries'},
  {value: 'Environmental Compliance', label: 'Environmental Compliance'},
  {value: 'Emergency Response Development', label: 'Emergency Response Development'},
  {value: 'HSE Consulting', label: 'HSE Consulting'},
  {value: 'Training', label: 'Training'},
  {value: 'Systems Management', label: 'Systems Management'}
  ]

const Contact = (data) => {
  const contactInfo = defaultContactInfo
  let title = 'Contact Us:'
  let subTitle = ''
  if(data.data && data.data.contentfulContactPage){
    const info = data.data.contentfulContactPage
    title = info.title
    contactInfo.canadaOfficePhoneNumber = info.canadaOfficePhoneNumber
    contactInfo.usaOfficePhoneNumber = info.usaOfficePhoneNumber
    contactInfo.canadaOfficeEmail = info.canadaOfficeEmail
    contactInfo.canadaOfficeAddressHTML = info.canadaOfficeAddress.childMarkdownRemark.html
    contactInfo.usaOfficeAddressHTML = info.usaOfficeAddress.childMarkdownRemark.html
    contactInfo.usaOfficeEmail = info.usaOfficeEmail
  }

  return (
    <>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <PageWrapper footerDark>
        <Section>
          <Container>
            <Row className="justify-content-center text-center">
              <Col lg="12">
                <div className="banner-content">
                  {subTitle !== ''
                      ? <Title>{title}: {subTitle}</Title>
                      : <Title>{title}</Title>}
                </div>
              </Col>
            </Row>
            <Row className="justify-content-center pt-3 pb-5">
              <Col lg="5">
                <div>
                  <Title
                      variant="card"
                      fontSize="24px"
                      className="mb-5 text-center">
                    Send us a message
                  </Title>
                  <FormStyled accept-charset="UTF-8" action="https://getform.io/f/89ae8e9c-c8b8-4e41-a640-9f5186408e12" method="POST">
                    <Box mb={4}>
                      <Title
                          variant="card"
                          fontSize="18px"
                          as="label"
                          htmlFor="nameput">
                        Your name
                      </Title>
                      <Input
                          type="text"
                          placeholder="i.e. John Smith"
                          id="nameput"
                          name="name" />
                    </Box>
                    <Box mb={4}>
                      <Title
                          variant="card"
                          fontSize="18px"
                          as="label"
                          htmlFor="emailput">
                        Email address
                      </Title>
                      <Input
                          type="email"
                          placeholder="i.e. john@email.com"
                          id="emailput"
                          name="email" />
                    </Box>

                    <Box mb={4}>
                      <Title
                          variant="card"
                          fontSize="18px"
                          as="label"
                          htmlFor="serviceput">
                        Subject
                      </Title>
                      <Select
                          options={subjectOptions}
                          id="serviceput"
                          name="service" />
                    </Box>

                    <Box mb={4}>
                      <Title
                          variant="card"
                          fontSize="18px"
                          as="label"
                          htmlFor="messageput">
                        Message
                      </Title>
                      <Input
                          type="text"
                          as="textarea"
                          placeholder="Write your message here"
                          rows={4}
                          id="messageput"
                          name="message" />
                    </Box>
                    <Button width="100%" type="submit" borderRadius={10}>
                      Send Message
                    </Button>
                  </FormStyled>
                </div>
              </Col>
              <Col lg="7">
                <Row className="justify-content-center pt-3 pb-5">
                  <Col sm="12">
                    <WidgetWrapper>
                      <Row>
                        <Col md="6" sm="6">
                          <Box className="mb-3">
                            <Title variant="card" fontSize="24px">
                              Canadian Office
                            </Title>
                          </Box>
                        </Col>
                        <Col md="6" sm="6">
                          <Box className="mb-3">
                            <Title variant="card" fontSize="24px">
                              American Office
                            </Title>
                          </Box>
                        </Col>
                      </Row>
                      <Row>
                        <Col md="6" sm="6">
                          <Box className="mb-3">
                            <Title variant="subtle">
                              Phone
                            </Title>
                            <Text>{contactInfo.canadaOfficePhoneNumber}</Text>
                          </Box>
                        </Col>
                        <Col md="6" sm="6">
                          <Box className="mb-3">
                            <Title variant="subtle">
                              Phone
                            </Title>
                            <Text>{contactInfo.usaOfficePhoneNumber}</Text>
                          </Box>
                        </Col>
                      </Row>
                      <Row>
                        <Col sm="6">
                          <Box className="mb-3">
                            <Title variant="subtle">
                              Email
                            </Title>
                            <Text>{contactInfo.canadaOfficeEmail}</Text>
                          </Box>
                        </Col>
                        <Col sm="6">
                          <Box className="mb-3">
                            <Title variant="subtle">
                              Email
                            </Title>
                            <Text>{contactInfo.usaOfficeEmail}</Text>
                          </Box>
                        </Col>
                      </Row>
                    </WidgetWrapper>
                  </Col>
                </Row>
                <Row className="pt-5 pt-lg-0 justify-content-center text-center">
                  <Col lg="8">
                    <h3>Office Locations</h3>
                  </Col>
                </Row>
                <Row className="justify-content-center pt-3 pb-5">
                  <Col sm="12">
                    <WidgetWrapper>
                      <Row>
                        <Col xs="6">
                          <Box className="mb-3">
                            <Text>Vancouver, BC, Canada</Text>
                          </Box>
                          <Box className="mb-3">
                            <Text>Seattle, WA, USA</Text>
                          </Box>
                          <Box className="mb-3">
                            <Text>Panama City, Panama</Text>
                          </Box>
                        </Col>
                        <Col xs="6">
                          <Box className="mb-3">
                            <Text>Lecce, Puglia, Italy</Text>
                          </Box>
                          <Box className="mb-3">
                            <Text>Almaty, Kazakhstan</Text>
                          </Box>
                        </Col>
                      </Row>
                    </WidgetWrapper>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Container>
        </Section>
      </PageWrapper>
    </>
  );
};
export const query = graphql`
  {
    contentfulContactPage(title: {eq: "Contact Us"}) {
      title
      usaOfficePhoneNumber
      canadaOfficePhoneNumber
      trainingDeptEmail
      usaOfficeEmail
      systemsManagementEmail
      canadaOfficeEmail
      emergencyResponseDevelopmentEmail
      environmentalComplianceEmail
      hseConsulting
      usaOfficeAddress {
        childMarkdownRemark {
          html
        }
      }
      canadaOfficeAddress {
        childMarkdownRemark {
          html
        }
      }
    }
  }`
export default Contact;
